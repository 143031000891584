<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Department</strong>
          </CCardHeader>
          <CRow>
            <CCol md="10">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Department Name *"
                    v-model="department.departmentName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Department Name'"
                    @blur="$v.department.departmentName.$touch()"
                    :is-valid="
                      $v.department.departmentName.$dirty
                        ? !$v.department.departmentName.$error
                        : null
                    "
                  />
                  <CInput
                    label="EDRS Department Name"
                    v-model="department.edrsDepartmentName"
                    horizontal
                    :disabled="mode.viewMode"
                  />
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                      >Type Of Department</label
                    >
                    <div class="col-sm-4">
                      <input
                        type="radio"
                        v-model="department.departmentType"
                        value="1"
                        :disabled="mode.viewMode"
                      />
                      External &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                      <input
                        type="radio"
                        v-model="department.departmentType"
                        value="2"
                        :disabled="mode.viewMode"
                      />
                      Internal
                    </div>
                  </div>
                  <MultiSelect
                    label="Models"
                    :options="allModels"
                    :hide-selected="false"
                    :searchable="true"
                    optionLabel="modelName"
                    :disabled="mode.viewMode"
                    track-by="modelId"
                    :track-ids.sync="department.modelIds"
                    idParamName="modelId"
                    :itemTypeisString="false"
                    multiple
                  ></MultiSelect>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import MultiSelect from "../../containers/MultiSelect";

import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Department",
  components: {
    MultiSelect,
  },
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      departmentId: "",
      subGroupId: "",
      department: {
        departmentId: "0",
        edrsDepartmentName: "",
        subGroupId: "",
        departmentName: "",
        departmentType: "0",
        modelIds: [],
      },
      allModels: [],
      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    department: {
      departmentName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.subGroupId = this.$route.params.subGroupId;
      this.getAllmodels();
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.departmentId = this.$route.params.departmentId;
      this.subGroupId = this.$route.params.subGroupId;
      this.getUser();
      this.getAllmodels();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.departmentId = this.$route.params.departmentId;
      this.subGroupId = this.$route.params.subGroupId;
      this.getUser();
      this.getAllmodels();
    }
  },
  components: { datetime, MultiSelect },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Departments",
        params: { subGroupId: this.$route.params.subGroupId },
      });
    },

    Save() {
      this.$v.department.$touch();
      if (this.$v.department.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.department));
      } else {
        fetch(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/save/sub/department/" +
            this.subGroupId,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(this.department),
          }
        );
        window.history.back();
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/department/" +
          this.departmentId
      )
        .then((response) => response.json())
        .then((data) => (this.department = data));
    },

    getAllmodels() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/models"
      )
        .then((response) => response.json())
        .then((data) => (this.allModels = data));
      console.log(this.allModels);
    },
  },
  mounted() {
    this.getAllmodels();
  },
};
</script>
